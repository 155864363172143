import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../../components/layout.de"
import Nav from "../../components/Nav.de"

// data
const lp = {
  navLandingPage: {
    navTelefonNumber: '+49 30 9203854012',
    navTelefonCta: 'Get answers',
    navTelefonNumberText: '+49 30 9203854012',
    navContactCtaMobile: 'Schedule a call',
    navContactCtaDesktop: 'Contact',
  },
}

export const featureImage = graphql`fragment featureImage on File {
  childImageSharp {
    gatsbyImageData(layout: FULL_WIDTH)
  }
}
`
export const query = graphql`query IndexDeQuery {
  yacht: file(relativePath: {eq: "sailing-yacht-on-water-in-sunset.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED, aspectRatio: 1.333)
    }
  }
  widgets: file(relativePath: {eq: "widget04.png"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED, aspectRatio: 1.333)
    }
  }
  jobs: file(relativePath: {eq: "thumbnail-jobs.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED, aspectRatio: 1.333)
    }
  }
  sup: file(relativePath: {eq: "woman-and-dog-on-a-sup.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED, aspectRatio: 1.333)
    }
  }
}
`

// markup
const IndexPageDe = ({ data }) => {
  return (
    <Layout>
      <Nav
        telNumber={lp.navLandingPage.navTelefonNumber}
        telCta={lp.navLandingPage.navTelefonCta}
        telNumberText={lp.navLandingPage.navTelefonNumberText}
        contactCtaMobile={lp.navLandingPage.navContactCtaMobile}
        contactCtaDesktop={lp.navLandingPage.navContactCtaDesktop}
      />
      <main className="p-4 mx-auto my-16 max-w-screen-3xl">
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 sm:gap-6 md:gap-12">
          <Link to="/widgets" className="relative block">
            <div className="absolute z-10 flex items-center w-full h-full text-white bg-black bg-opacity-50">
              <h2 className="mx-auto">Widgets</h2>
            </div>
            <GatsbyImage
              image={data.widgets.childImageSharp.gatsbyImageData}
              className="min-h-56"
              alt="" />
          </Link>
          <Link to="/de/yachten" className="relative block">
            <div className="absolute z-10 flex items-center w-full h-full text-white bg-black bg-opacity-50">
              <h2 className="mx-auto">Yachten</h2>
            </div>
            <GatsbyImage
              image={data.yacht.childImageSharp.gatsbyImageData}
              className="min-h-56"
              alt="" />
          </Link>
          {/* <Link to="/jobs" className="relative block">
            <div className="absolute z-10 flex items-center w-full h-full text-white bg-black bg-opacity-50">
              <h2 className="mx-auto">Jobs</h2>
            </div>
            <GatsbyImage
              image={data.jobs.childImageSharp.gatsbyImageData}
              className="min-h-56"
              alt="" />
          </Link> */}
          {/* <Link to="/de/sups" className="relative block">
            <div className="absolute z-10 flex items-center w-full h-full text-white bg-black bg-opacity-50">
              <h2 className="mx-auto">SUPs</h2>
            </div>
            <Img fluid={data.sup.childImageSharp.fluid} className="min-h-56" alt="TODO" />
          </Link> */}
        </div>
          {/* <Link to="/website-integration">/website-integration</Link>
          <Link to="/website-integration/individual-look-and-feel">/website-integration/individual-look-and-feel</Link>
          <Link to="/website-integration/quick-start-with-rentware-shop">/website-integration/quick-start-with-rentware-shop</Link>
          <Link to="/website-integration/various-widget-types">/website-integration/various-widget-types</Link> */}
      </main>
    </Layout>
  );
}

export default IndexPageDe
